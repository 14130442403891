import { setCMSBarWidth } from './cms-bar-width'


/* global gettext */

import './select_checkbox'
import './required_checkbox'
import { commercialTable } from './commercial_table'
import {
    openAccordingCollapse,
    collapseAddNameToItems,
    collapseOpenItemWhenFragment
} from './collapse'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    for (const svg of document.querySelectorAll('.cooperation aside img')) {
        svg.insertAdjacentHTML('afterend', `<div class="info-tooltip" >${svg.alt}</div>`)
    }

    for (const item of document.querySelectorAll('.prevent-default')) {
        item.href = ''
    }

    // Add subject for link with mailto
    for (const link of document.querySelectorAll('a[href^="mailto"]')) {
        if (link.dataset.subject) {
            link.href = `${link.href}?subject=${link.dataset.subject}`
        }
    }

    // Open first nav tab
    for (const tabs of document.querySelectorAll('.nav.nav-tabs')) {
        tabs.querySelector('.nav-item button').click()
    }

    // If url has hash try to open collapse
    if (document.getElementById(`${window.location.hash.replace('#', '')}`)) {
        document.getElementById(`${window.location.hash.replace('#', '')}`).click()
    }

    // Open navigation items in a new tab
    for (const link of document.querySelectorAll('.page-navigation a[href]')) {
        if (!link.href.includes(window.location.host)) {
            link.setAttribute('target', '_blank')
        }
    }
    commercialTable()

    const auctionNavControl = document.querySelector('.auction-nav-control')
    if (auctionNavControl) {
        for (const navItemButton of auctionNavControl.querySelectorAll('.nav-item button')) {
            const table = document.querySelector(`#${navItemButton.getAttribute('aria-controls')} table tbody`)
            const rowsLength = table.getElementsByTagName("tr").length
            const getTotal = auctionNavControl.querySelector(`
                    .bg-body[aria-labelledby=${navItemButton.id}] .auctions-total
                `).textContent.trim()
            const getTotalNum = Number(getTotal)
            if (getTotalNum > 0 && getTotalNum < rowsLength) {
                navItemButton.insertAdjacentHTML('beforeend', `
                <span>${getTotal}/${getTotal}</span>
                `)
            } else if (getTotalNum >= rowsLength) {
                navItemButton.insertAdjacentHTML('beforeend', `
                <span>${rowsLength}/${getTotal}</span>
                `)
            }
        }
    }

    openAccordingCollapse()
    collapseAddNameToItems()
    collapseOpenItemWhenFragment()

    for (const table of document.querySelectorAll('.table-collapse table')) {
        const rows = table.getElementsByTagName("tr")
        let rowHeight = 0
        const tableHeight = table.offsetHeight
        if (rows.length >= 8) {
            for (let i = 0; i < 8; i++) {
                rowHeight += rows[i].offsetHeight
            }
            table.style.height = `${rowHeight}px`

            table.insertAdjacentHTML('afterend', `
                <div class="table-btn-show-all">
                    <a class="show-more">${gettext('Show all')}</a>
                    <a class="show-less d-none">${gettext('Show less')}</a>
                </div>
            `)

            const showAllElem = table.nextElementSibling
            const showMore = showAllElem.querySelector('.show-more')
            const showLess = showAllElem.querySelector('.show-less')

            showMore.addEventListener('click', () => {
                table.style.height = `${tableHeight - 1}px`
                showMore.classList.add('d-none')
                showLess.classList.remove('d-none')
            })

            showLess.addEventListener('click', () => {
                table.style.height = `${rowHeight}px`
                showMore.classList.remove('d-none')
                showLess.classList.add('d-none')
            })
        }
    }

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})

addEventListener("resize", commercialTable)
